<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <p><b class="red">“6359”管理理念</b></p>
        <p>
          6个一流：一流文化、一流人才、一流团队、一流管理、一流平台、一流品质；
        </p>
        <p>3情原则：有情管理、无情制度、绝情处理；</p>
        <p>
          5项能力：素质素养提升、文字综合能力、语言表达能力、专业技术能力、解决问题能力；
        </p>
        <p>9字要求：廉、精、细、严、实、效、果、公、私。</p>
        <p><b class="red">企业使命</b></p>
        <p>
          以“建设世界一流汽车城先行者”为使命，配合产业发展、提速企业发展、助推区域发展。
        </p>
        <p><b class="red">企业精神</b></p>
        <p>
          转型转变、创新创建、开辟新局、永争第一。奋斗奋进、奋发有为、共建共享、协同协作。
        </p>
        <p><b class="red">企业愿景</b></p>
        <p>
          对标国内优秀国企及一流平台公司，积极整合资产资源要素，健全治理结构，增强经营管理职能，实现“融、投、建、管、退”全过程闭环，提高国有资本运管效率，增强国有企业活力，逐步实现国有资本投资运营市场化，努力建设成为具有强大资本实力和可持续发展能力的国内一流平台企业集团。
        </p>
        <p><b class="red">企业核心价值观</b></p>
        <p>勇于担当、立于品质、恒于诚信、勤于协作、乐于奉献</p>
        <p><b class="red">企业工作标准</b></p>
        <p>
          1．创一流，争先进：具备强烈的争先创优意识，“人人争一流，事事争一流，时时争一流，永远争一流”。敢于和自己较劲，做到唯先是争；敢于和他人叫板，做到比成绩、争名次。克服凑合心理，做到“人无我有、人有我特、人特我优”，杜绝不思进取、碌碌无为、甘居下游。
        </p>
        <p>
          2．能吃苦，耐磨炼：勇于吃苦，不计辛苦，不怕艰苦；做到“能吃苦，耐磨炼，能干事，干成事”。具有进取精神，全心全意投入，能进行自我管理、自主学习，不依靠监督、控制来完成任务；具有把工作当成事业看待的职业素质和敬业精神。
        </p>
        <p>
          3．高标准，严要求：坚持工作高起点、高标准、高质量，摒弃“小事应付、大事凑合”的蒙混思想和交差心理，不降低标准，不放松要求。严格管理、严格督办、严格奖惩，做到日事日毕、日清日高，工作质量与业绩考核相结合。
        </p>
        <p>
          4．结果导向，主动作为：精心策划，狠抓落实，抓具体、重细节，认真实施过程管控，强调结果导向，做到“有计划、有监督、有结果、有总结，处处有反馈、事事有回音”。培养善于谋划、工作勤勉、鞠躬尽瘁的优良作风。不仅要干事，还要快干事、干成事、不出事。做到谋其政、尽其责，对熟悉的业务要真正起到作用，对不熟悉的业务要尽到责任。
        </p>
        <p>
          5．攻坚克难，精益求精：目标坚定、意志坚强，知责而为、迎难而上，培养啃硬骨头的能力。对于任务不讲条件，事不避难，不折不扣，坚决执行。具备精湛的业务技能、精益求精的工匠精神，“个人不满意不出个人，部门不满意不出部门，公司不满意不出公司”，凡事精益求精，力臻完美。
        </p>
        <p>
          6．尽心尽责，敢于担当：强化履职能力，本职工作高效保质，认真履行部门职能。杜绝“身不在岗，心不在责”，牢记“多看一眼，安全保险”，时刻考虑“职责尽到了没有、尽好了没有、尽够了没有”。强化领导责任，强调问责追究，勇于承担责任，做到“我就是最后一关，责任到此，绝不再推”。
        </p>
        <p>
          7．积极进取，永不自满：摒弃小富即安、小进即满、不求有功，但求无过、比上不足、比下有余的消极思想，树立“前有标兵、后有追兵”的忧患意识，强化“不进是退，慢进也是退”的赶超意识，培养优良上进的学习风气，增强加快发展的责任心和紧迫感。
        </p>
        <p>
          8．雷厉风行，真抓实干：专业高效、紧张有序，高效运行，加快发展速度。杜绝懈怠心理，做到言必行，行必果，定下的事能办的立即办，该什么时间完成就什么时间完成，不拖沓滞后，不敷衍塞责。
        </p>
        <p>
          9．博采众长，开放创新：保持前瞻性、战略性，强化胸怀全局、善谋全局、服务全局的战略思维，避免自我封闭和闭门造车。放活思想、放大胆量，主动思考，加强学习，做到海纳百川，借脑、借力、借势发展。打造富有激情、充满智慧的学习型团队，积极求变，奋力创新。
        </p>
        <p>
          10．负重加压，奋力赶超：切实改进工作作风，激发干事激情和奋斗热情。树立强烈的责任感，不等推，不等催，自我负重，主动加压，勇于挑重担、打硬仗，比照行业标杆奋力赶超，杜绝消极懈怠，得过且过、安于现状。
        </p>
        <p><b class="red">企业行为准则</b></p>
        <p>1．仪表形‎象规范：衣着得‎体，仪表整洁，气质端庄，形象职业。</p>
        <p>
          2．言行举止规范：言谈礼貌，谈吐得当，进退有度，举止大方，情绪稳定。
        </p>
        <p>3．工作纪律规范：遵章守纪，履职尽责，坚守道德，团结合作。</p>
        <p>
          4．工作态度规范：爱岗敬业，强调执行，承担奉献，尊重领导，乐于助人，积极自信。
        </p>
        <p>
          5．待人接物规范：加强修养，严于律己，宽以待‎人；接待客人，来有应声，去有答语；接听电话，礼貌用语；参加会议，保持秩序；宴会聚餐，遵守礼仪；日常沟通，事事必回。
        </p>
        <p>
          6．办公文明规范：严禁吸烟，卫生整洁，保持安静，坚守岗位，注重隐私，厉行节约。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner1.jpg"),
    };
  },
  components: { Crumb, Banner },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .title {
      text-align: center;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;
      h1 {
        font-size: 28px;
        line-height: 60px;
        font-weight: normal;
        text-align: center;
      }
      p {
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        span {
          margin: 0 20px;
        }
      }
    }
    .content {
      p {
        margin-bottom: 1em;
      }
      .align-right {
        text-align: right;
      }
      .red {
        color: #ff0000;
      }
    }
  }
}
</style>
